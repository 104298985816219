import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import FormData from 'form-data'
import forEach from 'lodash/forEach'
import upperCase from 'lodash/upperCase'
import startCase from 'lodash/startCase'
import replace from 'lodash/replace'
import join from 'lodash/join'
import isEmpty from 'lodash/isEmpty'
import split from 'lodash/split'
import find from 'lodash/find'
import lowerCase from 'lodash/lowerCase'
import sortBy from 'lodash/sortBy'
import debounce from 'lodash/debounce'


// Base Page
import BasePage from '@/pages/Base'

// Local Components
import ProductDetail from '@/containers/ProductDetail/ProductDetail.vue'

// Services
import ProductService from '@/services/Products'
import CountryService from '@/services/Country'
import ProvinceSevice from '@/services/Province'
import CitiesService from '@/services/City'
import SupplierService from '@/services/Suppliers'
import WarehouseService from '@/services/Warehouses/warehouses'
import sumBy from 'lodash/sumBy'
import Suppliers from '@/services/Suppliers'
import Warehouses from '@/services/Warehouses/warehouses'

@Component({
  components: { ProductDetail }
})
export default class Products extends BasePage {
  constructor() {
    super()
  }

  // Search
  searchLoading: boolean = false
  searchInput: string = ''
  searchLabel: string = 'Search here...'
  searchHint: string = 'Search Anything Here'
  searchIcon: string = 'search'

  // Search by Title Original Language
  searchInputByTitleOriginalLanguage: string = ''

  // Search by Product ID
  searchInputByProductId: string = ''

  // Sync Product
  syncProductDialog: boolean = false
  syncProductId: string = ''
  maxSyncProductCharacter: number = 25

  // Table
  tableHeaders: object[] = [
    {
      text: 'ID',
      align: 'left',
      sortable: true,
      value: 'id',
      class: 'id-table-width'
    },
    {
      text: 'Image',
      align: 'center',
      sortable: false
    },
    {
      text: 'Product Name',
      align: 'left',
      sortable: true,
      value: 'title'
    },
    {
      text: 'Created at',
      align: 'center',
      sortable: true,
      value: 'created_at'
    },
    {
      text: 'Last Update',
      align: 'center',
      sortable: true,
      value: 'updated_at'
    },
    {
      text: 'Weight',
      align: 'right',
      sortable: true,
      value: 'weight'
    },
    {
      text: 'Price',
      align: 'center',
      sortable: true,
      value: 'price'
    },
    {
      text: 'Quantity',
      align: 'right',
      sortable: true,
      value: 'quantity'
    },
    {
      text: 'Priority',
      align: 'right',
      sortable: true,
      value: 'priority'
    },
    {
      text: 'Total Users',
      align: 'center',
      sortable: false
    },
    {
      text: 'Action',
      align: 'center',
      sortable: false,
      class: 'action-table-width'
    }
  ]
  tableItems: object[] = []
  tableTotalItems: number | string = 0
  tableRowsPerPageItems: number[] = [5, 10, 15, 25, 50, 100]
  tablePagination: any = {
    sortBy: 'id',
    sortBy_payload: '-id',
    page: 1,
    rowsPerPage: 25,
    descending: true,
    totalItems: 0
  }
  tableLoading: boolean = false
  productDeleteModal: boolean = false
  idDelete: any = null

  topCategoryForList = null
  categoryLevel2ForList = 'No Category'
  categoryLevel3ForList = 'No Category'

  // Category
  topCategory: number = null
  topCategoryName: string = ''
  categoryLevel2: number = null
  categoryLevel2Name: string = ''
  categoryLevel3: number = null
  categoryLevel3Name: string = ''
  topCategories: any = []
  categoriesLevel2: any = []
  categoriesLevel3: any = []

  loadingCategories: boolean = false
  loadingLevel2Categories: boolean = false
  loadingLevel3Categories: boolean = false

  noLevel2Categories: boolean = false
  noLevel3Categories: boolean = false

  filterIcon: string = 'mdi-filter-variant'
  filterLoading: boolean = false

  // Filter by Country
  filterItemsCountry: any = {
    items: [],
    keyword: null
  }
  filterCountrySelected: string = ''
  searchCountryDebounce: Function

  // Filter by Tag
  dataLabelTags: any = [
    {
      text: 'Retail',
      value: 2
    },
    {
      text: 'Wholesale',
      value: 1
    },
    {
      text: 'Hot Product',
      value: 3
    },
    {
      text: 'Local',
      value: 4
    },
    {
      text: 'Imported Product',
      value: 5
    },
    {
      text: 'Fulfillment',
      value: 6
    },
    {
      text: 'COD',
      value: 9
    },
    {
      text: 'Virtual Product',
      value: 8
    }
  ]
  filterTagSelected: any = []

  //Filter by Supplier Name
  filterSupplierName: any = {
    items: [],
    keyword: null,
    selected: '',
    loading: false
  }

  // Filter by Warehouse Name
  filterWarehouseName: any = {
    items: [],
    selected: '',
    keyword: null,
    loading: false
  }

  // Bulk Edit Action
  bulkEditAction: any = [
    {
      text: 'Bulk Edit Category',
      value: 1
    },
    {
      text: 'Bulk Edit Tag',
      value: 2
    },
    {
      text: 'Bulk Edit Weight',
      value: 3
    },
    {
      text: 'Bulk Edit Country',
      value: 4
    },
    {
      text: 'Bulk Edit Warehouse',
      value: 5
    },
    {
      text: 'Bulk Edit Not Show In Catalog',
      value: 6
    },
    {
      text: 'Bulk Delete',
      value: 7
    }
  ]
  filterBulkEditSelected: any = []
  filterAttachmentToMyproducts: boolean = false
  filterNotQuantityProducts: boolean = false
  filterNotCountryId: boolean = false
  filterAddedByUser: boolean = false
  filterAddedByAdmin: boolean = false
  selectedProductID: any = []
  checkboxAllSelected: boolean = false
  dataProductAllSelected: any = {}
  setProductId: any = []

  // Bulk Edit Weight
  bulkEditWeightDialog: boolean = false
  bulkEditWeight: any = {
    weight: '',
    unit: {
      items: ['Kg', 'Gr'],
      selected: ''
    }
  }

  // Bulk Edit Warehouse
  bulkEditWarehouseDialog: boolean = false
  warehouseItems: any = []
  warehouseSelected: string = '';
  warehouseDialogData: any = {
    loading: false,
    keyword: null
  }
  warehouseId: any = null;

  filterWarehouseSelected: string = ''
  searchWarehouseDebounce: Function

  // Bulk Edit Country
  bulkEditCountryDialog: boolean = false
  countries: any = {
    items: [],
    keyword: null,
    loading: false,
    selected: null
  }
  searchDebounce: Function
  provinces: any = {
    items: [],
    keyword: null,
    loading: false,
    selected: null
  }
  cities: any = {
    items: [],
    keyword: null,
    loading: false,
    selected: null
  }

  // Bulk Edit NotShowInCatalog
  bulkEditnotShowinCatalogDialog: boolean = false
  notShowinCatalog: boolean = false

  // Bulk Edit Tag
  bulkEditTagDialog: boolean = false
  labelNames: any = [
    {
      text: 'Retail',
      value: 2
    },
    {
      text: 'Wholesale',
      value: 1
    },
    {
      text: 'Hot Product',
      value: 3
    },
    {
      text: 'Local',
      value: 4
    }
  ]
  selectedLabel: any = []


  // Bulk Edit Categories
  bulkEditCategoryDialog: boolean = false

  topCategoryBulkEdit: number = null
  categoryLevel2BulkEdit: number = null
  categoryLevel3BulkEdit: number = null

  topCategoriesBulkEdit: any = []
  categoriesLevel2BulkEdit: any = []
  categoriesLevel3BulkEdit: any = []

  loadingCategoriesBulkEdit: boolean = false
  loadingLevel2CategoriesBulkEdit: boolean = false
  loadingLevel3CategoriesBulkEdit: boolean = false

  noLevel2CategoriesBulkEdit: boolean = false
  noLevel3CategoriesBulkEdit: boolean = false

  // Bulk Delete
  alert_bulkDelete: boolean = false

  // collector modal
  showCollectorModal: boolean = false
  tableHeadersCollector: object[] = [
    {
      text: 'ID',
      align: 'left',
      sortable: true,
      value: 'id',
      class: 'id-table-width'
    },
    {
      text: 'Name',
      align: 'left',
      sortable: false,
      value: 'name'
    }
  ]
  tableItemsCollector: object[] = []
  tableTotalItemsCollector: number | string = 0
  tableRowsPerPageItemsCollector: number[] = [5, 10, 15, 25, 50, 100]
  tablePaginationCollector: any = {
    page: 1,
    rowsPerPage: 10,
    descending: true,
    totalItems: 0
  }
  tableLoadingCollector: boolean = false
  selectedIdProducts: string = ''

  // Params from manage category
  id_category_params = null
  id_category_params_array = null

  opts: any = {
    params: {
      'filter[level][is]': '1',
      'include': 'child'
    }
  }

  showDialogOwner: boolean = false
  owner_data: any = []
  ownerHeader: object[] = [
    {
      text: 'ID',
      align: 'left',
      sortable: true,
      value: 'id'
    },
    {
      text: 'Name',
      align: 'left',
      sortable: true,
      value: 'name'
    },
    {
      text: 'E-mail',
      align: 'left',
      sortable: true,
      value: 'email'
    },
    {
      text: 'Membership Name',
      align: 'left',
      sortable: true,
      value: 'membership'
    }
  ]
  showOwner(data){
    this.owner_data = []
    this.owner_data = data
    this.showDialogOwner = true
  }

  async mounted() {
    this.getSupplier()
    await this.getProductLists()
    this.getCategories()
    this.getCategoriesBulkEdit()
    this.searchDebounce = debounce(this.getCountries, 500)
    this.searchCountryDebounce = debounce(this.getCountry, 500)
    this.getWarehouses()
  }

  @Watch('topCategory', { deep: true })
  onChangedTopCategory(to: string, from: string) {
    this.getNextCategories(to, true)
  }
  @Watch('categoryLevel2', { deep: true })
  onChangedChildCategory(to: string, from: string) {
    this.getNextCategories(to)
  }
  @Watch('topCategoryBulkEdit', { deep: true })
  onChangedTopCategoryBulkEdit(to: string, from: string) {
    this.getNextCategoriesBulkEdit(to, true)
  }
  @Watch('categoryLevel2BulkEdit', { deep: true })
  onChangedChildCategoryBulkEdit(to: string, from: string) {
    this.getNextCategoriesBulkEdit(to)
  }

  @Watch('tablePagination', { deep: true })
  async onChanged() {
    this.$vuetify.goTo(0)
    this.getCountry()
    this.getWarehouses()
    await this.getProductLists()
  }

  @Watch('tablePaginationCollector', { deep: true })
  async onChangedCollector() {
    await this.getCollectors()
  }

  // @Watch('filterSupplierName.keyword')
  // onChangeSupplierName(){
  //   this.getSupplier()
  // }

  // @Watch('filterItemsCountry.keyword')
  // onChangedCountry() {
  //   this.searchCountryDebounce()
  // }

  @Watch('provinces.keyword')
  onProvincesChanged() {
    this.getProvinces(this.countries.selected)
  }

  @Watch('cities.keyword')
  onCitiesChanged() {
    this.getCities(this.provinces.selected)
  }

  onReset(){
    this.filterCountrySelected = ''
    this.filterSupplierName.selected = ''
    this.filterWarehouseName.selected = ''
    this.filterTagSelected = ''
    this.filterWarehouseSelected = ''
    this.filterAttachmentToMyproducts = false
    this.filterNotQuantityProducts = false
    this.filterNotCountryId = false
    this.filterAddedByUser = false
    this.filterAddedByAdmin = false
    this.searchInput = ''
    this.searchInputByTitleOriginalLanguage = ''
    this.searchInputByProductId = ''
    this.onChanged()
  }

  // Filter by Country
  async getCountry() {
    try {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 250,
          'filter[name][like]': ''
        }
      }

      if (!isEmpty(this.filterItemsCountry.keyword)) {
        var textFilter = 'filter[name][like]'
        opts.params[textFilter] = this.filterItemsCountry.keyword
      } else {
        var textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
      }

      const response = await CountryService.getCountries(opts)
      const countryDatas = response.data.data
      for (const countryData of countryDatas) {
        const status: any = {
          text: this.toStartCase(countryData.attributes.name),
          value: countryData.attributes.id
        }
        this.filterItemsCountry.items.push(status)
      }

    } catch (error) {
      this.catchHandler(error)
    }
  }

  // Filter by Supplier Name
  async getSupplier(){
    this.filterSupplierName.loading = true
    try{
      const opts: any = []

      if(!isEmpty(this.filterSupplierName.keyword)){
        opts.params = {
          'filter[name][like]': this.filterSupplierName.keyword
        }
      }

      opts.params = {
        'page[num]': 1,
        'page[limit]': 9999
      }

      const response = await SupplierService.getSuppliers(opts)

      console.log(response, 'getSupplier response');

      forEach(response.data, supplier => {
        const detail = {
          text : supplier.attributes.name
          // value: supplier.attributes.id
        }

        this.filterSupplierName.items.push(detail)
      })
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.filterSupplierName.loading = false
    }
  }

  // Filter by Category
  async getCategories() {
    try {
      // this.loadingCategories = true
      const responseCategories = await ProductService.getTopLevelCategories(this.opts)
      forEach(responseCategories.data.data, category => {
        let categoryMap = {
          categoryName: category.attributes.name,
          categoryId: category.attributes.id
        }

        this.topCategories.push(categoryMap)
      })
    } catch (error) {
      this.catchHandler(error)
    } finally {
      // this.loadingCategories = false
    }
  }

  async getNextCategories(categoryId, topCategory = false) {
    this.getProductLists()
    try {
      //set parentId
      let parentId = categoryId

      if (topCategory) {
        this.loadingLevel2Categories = true
        this.noLevel2Categories = false
        this.noLevel3Categories = false
        this.categoriesLevel2 = []
        this.categoriesLevel3 = []
      } else {
        this.loadingLevel3Categories = true
        this.noLevel3Categories = false
        this.categoriesLevel3 = []
      }

      const responseData = await ProductService.getLowLevelCategories(parentId)

      if (responseData.data.data.length > 0) {
        //assign response data to global object
        forEach(responseData.data.data, category => {
          let categoryMap = {
            categoryName: category.attributes.name,
            categoryId: category.attributes.id
          }

          if (topCategory) {
            this.categoriesLevel2.push(categoryMap)
            this.loadingLevel2Categories = false
          } else {
            this.categoriesLevel3.push(categoryMap)
            this.loadingLevel3Categories = false
          }
        })
      } else {
        //No category dimmers if no data
        if (topCategory) {
          this.loadingLevel2Categories = false
          this.noLevel2Categories = true
        } else {
          this.loadingLevel3Categories = false
          this.noLevel3Categories = true
        }
      }
    } catch (error) {
      this.catchHandler(error)
    }
  }

  async getNextCategories2(id, name) {
    this.topCategory = id
    this.topCategoryName = name
    this.getProductLists()
  }
  async getNextCategories3(id, name) {
    this.categoryLevel2 = id
    this.categoryLevel2Name = name
    this.getProductLists()
  }
  async getNextCategories4(id, name) {
    this.categoryLevel3 = id
    this.categoryLevel3Name = name
    this.getProductLists()
  }
  async onChangeSelectTopCategory() {
    this.topCategories = []
    this.topCategory = null
    this.topCategoryName = null
    this.categoryLevel2 = null
    this.categoryLevel2Name = null
    this.categoryLevel3 = null
    this.categoryLevel3Name = null
    this.getProductLists()
    this.getCategories()
  }
  async onChangeSelectCategoryLevel1() {
    this.categoryLevel2 = null
    this.categoryLevel2Name = null
    this.categoryLevel3 = null
    this.categoryLevel3Name = null
    this.getProductLists()
    this.getNextCategories3(this.categoryLevel2, this.categoryLevel2Name)
    this.getNextCategories(this.topCategory, true)
  }
  async onChangeSelectCategoryLevel2() {
    this.categoryLevel3 = null
    this.categoryLevel3Name = null
    this.getProductLists()
    this.getNextCategories4(this.categoryLevel3, this.categoryLevel3Name)
    this.getNextCategories(this.categoryLevel2, false)
  }
  async onChangeSelectCategoryLevel3() {
    this.getProductLists()
    this.getNextCategories4(this.categoryLevel3, this.categoryLevel3Name)
    this.getNextCategories(this.categoryLevel3, false)
  }

  // Bulk Edit Action
  async bulkEditActionButtonSubmit() {
    if (this.filterBulkEditSelected === 1) {
      this.bulkEditCategoryDialog = true
    } else if (this.filterBulkEditSelected === 2) {
      this.bulkEditTagDialog = true
    } else if (this.filterBulkEditSelected === 3) {
      this.bulkEditWeightDialog = true
    } else if (this.filterBulkEditSelected === 4) {
      this.getCountries()
      this.bulkEditCountryDialog = true
    } else if (this.filterBulkEditSelected === 5) {
      this.getWarehouses()
      this.bulkEditWarehouseDialog = true
    } else if (this.filterBulkEditSelected === 6) {
      this.bulkEditnotShowinCatalogDialog = true
    } else if (this.filterBulkEditSelected === 7) this.alert_bulkDelete = true
  }

  // Bulk Edit Categories
  async getCategoriesBulkEdit() {
    try {
      this.loadingCategoriesBulkEdit = true
      const responseCategories = await ProductService.getTopLevelCategories(this.opts)
      forEach(responseCategories.data.data, category => {
        let categoryMap = {
          categoryName: category.attributes.name,
          categoryId: category.attributes.id
        }

        this.topCategoriesBulkEdit.push(categoryMap)
      })
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.loadingCategoriesBulkEdit = false
    }
  }

  async getNextCategoriesBulkEdit(categoryId, topCategoryBulkEdit = false) {
    try {
      //set parentId
      let parentId = categoryId

      if (topCategoryBulkEdit) {
        this.loadingLevel2CategoriesBulkEdit = true
        this.noLevel2CategoriesBulkEdit = false
        this.noLevel3CategoriesBulkEdit = false
        this.categoriesLevel2BulkEdit = []
        this.categoriesLevel3BulkEdit = []
      } else {
        this.loadingLevel3CategoriesBulkEdit = true
        this.noLevel3CategoriesBulkEdit = false
        this.categoriesLevel3BulkEdit = []
      }

      const responseData = await ProductService.getLowLevelCategories(parentId)

      if (responseData.data.data.length > 0) {
        //assign response data to global object
        forEach(responseData.data.data, category => {
          let categoryMap = {
            categoryName: category.attributes.name,
            categoryId: category.attributes.id
          }

          if (topCategoryBulkEdit) {
            this.categoriesLevel2BulkEdit.push(categoryMap)
            this.loadingLevel2CategoriesBulkEdit = false
          } else {
            this.categoriesLevel3BulkEdit.push(categoryMap)
            this.loadingLevel3CategoriesBulkEdit = false
          }
        })
      } else {
        //No category dimmers if no data
        if (topCategoryBulkEdit) {
          this.loadingLevel2CategoriesBulkEdit = false
          this.noLevel2CategoriesBulkEdit = true
        } else {
          this.loadingLevel3CategoriesBulkEdit = false
          this.noLevel3CategoriesBulkEdit = true
        }
      }
    } catch (error) {
      this.catchHandler(error)
    }
  }

  toggleAll() {
    if (this.checkboxAllSelected === true) {
      forEach(this.tableItems, product => {
        this.dataProductAllSelected = product
        this.selectedProductID.push(this.dataProductAllSelected.productId)
      })
    } else {
      this.selectedProductID = []
    }
  }

  changeSort(column) {
    if (this.tablePagination.sortBy == column) {
      if (this.tablePagination.descending == true){
        this.tablePagination.sortBy_payload = column.replace('-', '')
        this.tablePagination.descending = !this.tablePagination.descending
      } else {
        this.tablePagination.sortBy_payload = '-' + column
        this.tablePagination.descending = !this.tablePagination.descending
      }
    } else {
      if (column != undefined){
        this.tablePagination.sortBy = column
        this.tablePagination.sortBy_payload = '-' + column
        this.tablePagination.descending = true
      }
    }
  }

  payload: any = []

  editCategory_byExpand(productId: any = [], data: any = {}){
    this.setProductId = []
    this.categoryLevel3BulkEdit = null
    this.categoryLevel2BulkEdit = null
    this.topCategoryBulkEdit = null

    this.setProductId.push(productId)
    this.bulkEditCategoryDialog = true
    this.topCategoryBulkEdit = data.topCategory
    this.categoryLevel2BulkEdit = data.categoryLevel2
    this.categoryLevel3BulkEdit = data.categoryLevel3
  }

  // Save Change Bulk Edit Category
  async saveChangeBulkEditCategory() {
    var data = this.setProductId
    if (this.setProductId.length != 0) data = this.setProductId
    else if(this.selectedProductID.length != 0) data = this.selectedProductID

    if (data.length != 0) {
      try {
        const valid = await this.$validator.validateAll()
        if (valid) {
          this.payload = {
            data: []
          }

          forEach(data, product => {
            if (this.categoryLevel3BulkEdit != null) {
              const data = {
                id: product,
                relationships: [
                  {
                    id: this.categoryLevel3BulkEdit,
                    type: 'categories'
                  }
                ]
              }
              this.payload.data.push(data)
            } else {
              const data = {
                id: product,
                relationships: [
                  {
                    id: this.categoryLevel2BulkEdit,
                    type: 'categories'
                  }
                ]
              }
              this.payload.data.push(data)
            }
          })
          this.showLoading({ text: 'Saving...' })
          const response = await ProductService.updateBulkCategoryAndTag(this.payload)
          this.showSnackbar({
            text: startCase(response.status === 200 ? 'Success' : 'Failed with HTTP error: ' + response.status),
            color: response.status === 200 ? 'green' : 'red',
            timeout: 1500
          })
          this.bulkEditCategoryDialog = false
          await this.getProductLists()
          this.filterBulkEditSelected = []
        } else {
          this.showSnackbar({
            text: 'Please check all fields requirements',
            color: 'red',
            timeout: 1500
          })
        }

      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.closeLoading()
      }
    } else {
      this.showSnackbar({ text: 'Please choose the products', color: 'red', timeout: 1500 })
      this.bulkEditCategoryDialog = false
    }
  }

  cancelModalBulkEditCategoryDialog() {
    this.filterBulkEditSelected = []
    this.categoryLevel3BulkEdit = null
    this.categoryLevel2BulkEdit = null
    this.topCategoryBulkEdit = null
    this.topCategoriesBulkEdit = []
    this.categoriesLevel2BulkEdit = []
    this.categoriesLevel3BulkEdit = []
    this.bulkEditCategoryDialog = false
    this.getCategoriesBulkEdit()
  }


  editnotShowinCatalog_byExpand(productId: any = [], data: any = []){
    this.setProductId = []

    this.setProductId.push(productId)
    this.notShowinCatalog = data.attributes.not_show_in_catalog

    this.bulkEditnotShowinCatalogDialog = true
  }

  // Save Change Bulk Edit Not Show In Catalog
  async saveChangeBulkEditnotShowinCatalog() {
    var data
    if (this.setProductId.length != 0) data = this.setProductId
    else if (this.selectedProductID.length != 0) data = this.selectedProductID

    if (data.length != 0) {
      try {
        const valid = await this.$validator.validateAll()
        if (valid) {
          this.payload = {
            data: []
          }

          forEach(data, product => {
            const data = {
              id: product,
              attributes:
                {
                  not_show_in_catalog: this.notShowinCatalog
                }
            }
            this.payload.data.push(data)

          })
          this.showLoading({ text: 'Saving...' })
          const response = await ProductService.updateBulkCategoryAndTag(this.payload)
          this.showSnackbar({
            text: startCase(response.status === 200 ? 'Success' : 'Failed with HTTP error: ' + response.status),
            color: response.status === 200 ? 'green' : 'red',
            timeout: 1500
          })
          this.bulkEditnotShowinCatalogDialog = false
          this.selectedProductID = []
          this.filterBulkEditSelected = []

          this.getProductLists()
        } else {
          this.showSnackbar({ text: 'Please check all fields requirements', color: 'red', timeout: 1500 })
        }
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.closeLoading()
      }
    } else {
      this.showSnackbar({ text: 'Please choose the products', color: 'red', timeout: 1500 })
      this.bulkEditnotShowinCatalogDialog = false
      this.filterBulkEditSelected = []
      this.notShowinCatalog = data.attributes.not_show_in_catalog
    }
  }



  //- Edit Tag
  editTag_byExpand(productId: any = [], data: any = []){
    this.selectedLabel = []
    this.setProductId = []

    this.setProductId.push(productId)
    this.selectedLabel = data
    this.bulkEditTagDialog = true
  }

  // Save Change Bulk Edit Tag
  async saveChangeBulkEditTag() {
    var data
    if (this.setProductId.length != 0) data = this.setProductId
    else if (this.selectedProductID.length != 0) data = this.selectedProductID

    if (data.length != 0) {
      try {
        const valid = await this.$validator.validateAll()
        if (valid) {
          this.payload = {
            data: []
          }

          forEach(data, product => {
            const data = {
              id: product,
              relationships: [
                {
                  id: this.selectedLabel,
                  type: 'tags'
                }
              ]
            }
            this.payload.data.push(data)

          })
          this.showLoading({ text: 'Saving...' })
          const response = await ProductService.updateBulkCategoryAndTag(this.payload)
          this.showSnackbar({
            text: startCase(response.status === 200 ? 'Success' : 'Failed with HTTP error: ' + response.status),
            color: response.status === 200 ? 'green' : 'red',
            timeout: 1500
          })
          this.bulkEditTagDialog = false
          this.selectedProductID = []
          this.filterBulkEditSelected = []
          this.selectedLabel = []

          this.getProductLists()
        } else {
          this.showSnackbar({ text: 'Please check all fields requirements', color: 'red', timeout: 1500 })
        }
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.closeLoading()
      }
    } else {
      this.showSnackbar({ text: 'Please choose the products', color: 'red', timeout: 1500 })
      this.bulkEditTagDialog = false
      this.selectedLabel = []
      this.filterBulkEditSelected = []
    }
  }

  cancelModalBulkEditTagDialog() {
    this.bulkEditTagDialog = false
    this.selectedLabel = []
    this.filterBulkEditSelected = []
  }

  editWeight_byExpand(productId: any = [], data: any = []){
    this.setProductId = []
    this.bulkEditWeight.unit.selected = ''

    this.bulkEditWeight.weight = data.weight
    this.bulkEditWeight.unit.selected = data.weight_unit
    this.bulkEditWeight.unit.items.push(data.weight_unit)
    this.setProductId.push(productId)
    this.bulkEditWeightDialog = true
  }

  // Save Change Bulk Edit Weight
  async saveChangeBulkEditWeight() {
    var data
    if (this.setProductId.length != 0) data = this.setProductId
    else if (this.selectedProductID.length != 0) data = this.selectedProductID

    if (data.length != 0) {
      try {
        const valid = await this.$validator.validateAll()
        if (valid) {
          this.payload = {
            data: []
          }

          forEach(data, product => {
            const data = {
              id: product,
              attributes: {
                weight: this.bulkEditWeight.weight,
                weight_unit: lowerCase(this.bulkEditWeight.unit.selected)
              }
            }
            this.payload.data.push(data)

          })
          this.showLoading({ text: 'Saving...' })

          const response = await ProductService.updateBulkCategoryAndTag(this.payload)
          this.showSnackbar({
            text: startCase(response.status === 200 ? 'Success' : 'Failed with HTTP error: ' + response.status),
            color: response.status === 200 ? 'green' : 'red',
            timeout: 1500
          })
          this.bulkEditWeightDialog = false
          this.selectedProductID = []
          this.filterBulkEditSelected = []
          this.bulkEditWeight.weight = ''
          this.bulkEditWeight.unit.selected = ''

          this.getProductLists()
        } else {
          this.showSnackbar({ text: 'Please check all fields requirements', color: 'red', timeout: 1500 })
        }
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.closeLoading()
      }
    } else {
      this.showSnackbar({ text: 'Please choose the products', color: 'red', timeout: 1500 })
      this.bulkEditTagDialog = false
      this.selectedLabel = []
      this.filterBulkEditSelected = []
    }
  }

  // Get Warehouse
  @Watch('warehouseDialogData.keyword')
  async onChangedWarehouse() {
    if (this.warehouseDialogData.Keyword == '') {
      this.warehouseId = null
      this.searchWarehouseDebounce = debounce(this.getWarehouses, 500)
      this.searchWarehouseDebounce()
    } else if (this.warehouseId == null || (find(this.warehouseItems, item => item.id == this.warehouseId) && find(this.warehouseItems, item => item.id == this.warehouseId).name != this.warehouseDialogData.keyword)) {
      this.searchWarehouseDebounce = debounce(this.getWarehouses, 500)
      this.searchWarehouseDebounce()
    }
  }

  //Get Warehouse Item
  async getWarehouses() {
    this.warehouseItems = []
    this.warehouseDialogData.loading = true
    try {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 25,
          sort: '-created_at',
          'filter[name][like]': ''
        }
      }
      if (!isEmpty(this.warehouseDialogData.keyword)) {
        var textFilter = 'filter[name][like]'
        opts.params[textFilter] = this.warehouseDialogData.keyword
        // opts.params.searchJoin = 'and'
      } else {
        var textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
        // delete opts.params.searchJoin
      }
      const response = await WarehouseService.getWarehouses(opts)

      const dataWarehouse = response.data

      for (const warehouseList of dataWarehouse) {
        const warehouse: any = {
          name: warehouseList.attributes.name,
          id: warehouseList.attributes.id
        }
        this.warehouseItems.push(warehouse)
        this.filterWarehouseName.items.push({
          text: warehouse.name,
          value: warehouse.id
        })
      }
      // }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.warehouseDialogData.loading = false
    }
  }

  editWarehouse_byExpand(productId: any = [], dataWarehouse){
    this.warehouseId = null

    this.getWarehouses()
    this.setProductId.push(productId)
    if (dataWarehouse.warehouse != null){
      this.warehouseItems.push(dataWarehouse.warehouse)
      this.warehouseId = dataWarehouse.warehouseId
    }
    this.bulkEditWarehouseDialog = true
  }

  // Save Changed Edit Warehouse
  async saveChangeBulkEditWarehouse() {
    var data
    if (this.setProductId.length != 0) data = this.setProductId
    else if (this.selectedProductID.length != 0) data = this.selectedProductID

    if (data.length != 0) {
      try {
        const valid = await this.$validator.validateAll()
        if (valid) {
          this.payload = {
            data: []
          }

          forEach(data, product => {
            const data = {
              id: product,
              relationships: [
                {
                  id: this.warehouseId,
                  type: 'warehouses'
                }
              ]
            }
            this.payload.data.push(data)

          })
          this.showLoading({ text: 'Saving...' })

          const response = await ProductService.updateBulkCategoryAndTag(this.payload)
          this.showSnackbar({
            text: startCase(response.status === 200 ? 'Success' : 'Failed with HTTP error: ' + response.status),
            color: response.status === 200 ? 'green' : 'red',
            timeout: 1500
          })
          this.bulkEditWarehouseDialog = false
          this.selectedProductID = []
          this.filterBulkEditSelected = []
          this.warehouseId = null

          this.getProductLists()
        } else {
          this.showSnackbar({ text: 'Please check all fields requirements', color: 'red', timeout: 1500 })
        }
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.closeLoading()
      }
    } else {
      this.showSnackbar({ text: 'Please choose the products', color: 'red', timeout: 1500 })
      this.bulkEditWarehouseDialog = false
      this.selectedLabel = []
      this.filterBulkEditSelected = []
    }
  }


  @Watch('countries.keyword')
  onChangedCountries() {
    this.searchDebounce()
  }

  // Get Countries, Provinces & Cities Item
  async getCountries() {
    try {
      this.countries.loading = true
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 25,
          'filter[name][like]': ''
        }
      }
      if (!isEmpty(this.countries.keyword)) {
        var textFilter = 'filter[name][like]'
        opts.params[textFilter] = this.countries.keyword
      } else {
        var textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
      }

      const response = await CountryService.getCountries(opts)
      const responseData = response.data.data
      for (const dataCountry of responseData) {
        this.countries.items.push(dataCountry.attributes)
      }
      this.countries.items = sortBy(this.countries.items, 'name')

    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.countries.loading = false
    }
  }

  async getProvinces(countryID){
    this.provinces.loading = true
    try{
      const opts: any = {
        params: { 'filter[country_id][is]': countryID }
      }

      if(!isEmpty(this.provinces.keyword)){
        opts.params = {
          ...opts.params,
          'filter[name][like]': this.provinces.keyword
        }
      }

      await ProvinceSevice.getProvinces(opts)
        .then(response => {
          for (const provinces of response.data.data){
            this.provinces.items.push(provinces.attributes)
          }
        })

    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.provinces.loading = false
    }
  }

  async getCities(provinceID){
    this.cities.loading = true
    try {
      const opts: any = {
        params: { 'filter[province_id][is]': provinceID }
      }

      if (!isEmpty(this.cities.keyword)){
        opts.params = {
          ...opts.params,
          'filter[name][like]': this.cities.keyword
        }
      }

      await CitiesService.getCities(opts)
        .then(response => {
          for (const citie of response.data.data){
            this.cities.items.push(citie.attributes)
          }
        })

    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.cities.loading = false
    }
  }

  editCountry_byExpand(productId: any = [], countryData){
    this.countries.selected = null
    this.provinces.selected = null
    this.cities.selected = null

    this.getCountries()
    this.setProductId.push(productId)
    if (countryData.country != null){
      this.countries.items.push(countryData.country)
      this.countries.selected = countryData.country.id
    }
    if (countryData.province != null){
      this.provinces.items.push(countryData.province)
      this.provinces.selected = countryData.province.id
    }
    if (countryData.city != null){
      this.cities.items.push(countryData.city)
      this.cities.selected = countryData.city.id
    }
    this.bulkEditCountryDialog = true
  }

  // Save Change Bulk Edit Country
  async saveChangeBulkEditCountry() {
    var data
    if (this.setProductId.length != 0) data = this.setProductId
    else if (this.selectedProductID.length != 0) data = this.selectedProductID

    if (data.length != 0) {
      try {
        const valid = await this.$validator.validateAll()
        if (valid) {
          this.payload = {
            data: []
          }

          forEach(data, product => {
            const data = {
              id: product,
              attributes: {
                country_id: this.countries.selected,
                province_id: this.provinces.selected,
                city_id: this.cities.selected
              }
            }
            this.payload.data.push(data)

          })
          this.showLoading({ text: 'Saving...' })

          const response = await ProductService.updateBulkCategoryAndTag(this.payload)
          this.showSnackbar({
            text: startCase(response.status === 200 ? 'Success' : 'Failed with HTTP error: ' + response.status),
            color: response.status === 200 ? 'green' : 'red',
            timeout: 1500
          })
          this.bulkEditCountryDialog = false
          this.selectedProductID = []
          this.filterBulkEditSelected = []
          this.countries.selected = ''

          this.getProductLists()
        } else {
          this.showSnackbar({ text: 'Please check all fields requirements', color: 'red', timeout: 1500 })
        }
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.closeLoading()
      }
    } else {
      this.showSnackbar({ text: 'Please choose the products', color: 'red', timeout: 1500 })
      this.bulkEditTagDialog = false
      this.selectedLabel = []
      this.filterBulkEditSelected = []
    }
  }


  responseProductList: any = []
  async getProductLists() {
    try {
      this.id_category_params = this.$route.query.categoryId
      this.id_category_params_array = this.$route.query.categoryIdUncategorized
      const opts: any = {
        params: {
          'page[num]': this.tablePagination.page,
          'page[limit]': this.tablePagination.rowsPerPage,
          // sort: this.tablePagination.descending ? '-id' : 'id',
          sort: this.tablePagination.sortBy_payload,
          include: 'categories,supplier,warehouses,source,variations,images,country,categories,cities,provinces,owner,creator'
        }
      }
      if (this.searchInput != '') {
        opts.params = {
          ...opts.params,
          'filter[title][like]': this.searchInput
        }
      } else {
        var textFilter = 'filter[title][like]'
        delete opts.params[textFilter]
      }

      // Search by Title Original Language
      if (this.searchInputByTitleOriginalLanguage != '') {
        opts.params = {
          ...opts.params,
          'filter[original_title][like]': this.searchInputByTitleOriginalLanguage
        }
      } else {
        var textFilter = 'filter[original_title][like]'
        delete opts.params[textFilter]
      }

      // Search by Product ID
      if (this.searchInputByProductId != '') {
        opts.params = {
          ...opts.params,
          'filter[id][is]': this.searchInputByProductId
        }
      } else {
        var textFilter = 'filter[id][is]'
        delete opts.params[textFilter]
      }

      // Filter by Tag/Label
      if (this.filterTagSelected != '') {
        opts.params = {
          ...opts.params,
          'filter[tags.id][is]': this.filterTagSelected
        }
      } else {
        var textFilter = 'filter[tags.id][is]'
        delete opts.params[textFilter]
      }

      // Filter by Country
      if (this.filterCountrySelected != '') {
        opts.params = {
          ...opts.params,
          'filter[country.id][is]': this.filterCountrySelected
        }
      } else {
        var textFilter = 'filter[country.id][is]'
        delete opts.params[textFilter]
      }

      // Filter by supplier name
      if (this.filterSupplierName.selected != '') {
        opts.params = {
          ...opts.params,
          'filter[supplier.name][like]': this.filterSupplierName.selected
        }
      }

      // Filter by warehouse name
      if (this.filterWarehouseName.selected != ''){
        opts.params = {
          ...opts.params,
          'filter[warehouses.id][like]': this.filterWarehouseName.selected
        }
      }

      if (this.topCategory != null) {
        opts.params = {
          ...opts.params,
          'filter[categories.id][is]': this.topCategory
        }
        if (this.categoryLevel2 != null) {
          opts.params = {
            ...opts.params,
            'filter[categories.id][is]': this.categoryLevel2
          }
          if (this.categoryLevel3 != null) {
            opts.params = {
              ...opts.params,
              'filter[categories.id][is]': this.categoryLevel3
            }
          }
        }
      }

      //Filter to grub switch
      if (this.filterAttachmentToMyproducts == true) {
        opts.params = {
          ...opts.params,
          'imported': this.filterAttachmentToMyproducts
        }
      }
      if (this.filterNotQuantityProducts == true) {
        opts.params = {
          ...opts.params,
          'filter[quantity]': 0
        }
      }
      if (this.filterNotCountryId == true) {
        opts.params = {
          ...opts.params,
          'filter[country_id][null]': this.filterNotCountryId
        }
      }
      if (this.filterAddedByUser == true){
        opts.params = {
          ...opts.params,
          'filter[source_id][is]': 1,
          'filter[merchant][null]': false
        }
      }
      if (this.filterAddedByAdmin == true){
        opts.params = {
          ...opts.params,
          'filter[source_id][is]': 1,
          'filter[merchant][null]': true
        }
      }

      // Filter from manage category
      if (this.id_category_params != 'null') {
        opts.params = {
          ...opts.params,
          'filter[categories.id][is]': this.id_category_params
        }
      } else if (this.id_category_params == 'null') {
        opts.params = {
          ...opts.params,
          include: 'supplier,warehouses,source,variations,images,country,categories,cities,provinces',
          uncategorized: true
        }
      }

      this.tableLoading = true
      if (this.id_category_params_array != undefined) {
        this.responseProductList = await ProductService.getUncategorizedProductsLists(this.id_category_params_array, opts)
      } else {
        this.responseProductList = await ProductService.getProductsLists(opts)
      }

      const included_categories = this.responseProductList.included.categories
      const included_image = this.responseProductList.included.images
      const included_country = this.responseProductList.included.country
      const included_cities = this.responseProductList.included.cities
      const included_supplier = this.responseProductList.included.supplier
      const included_warehouses = this.responseProductList.included.warehouses
      const included_notshowincatalog = this.responseProductList.included.notShowinCatalog
      const included_owner = this.responseProductList.included.owner
      const included_creator = this.responseProductList.included.creator
      this.tableTotalItems = this.responseProductList.meta.pagination.total
      this.tableItems = []

      forEach(this.responseProductList.data, dataProduct => {
        // check reseller price
        var set_resellerPrice = ''
        if (Number(dataProduct.attributes.reseller_price) > 0) {
          set_resellerPrice = this.formatMoney(dataProduct.attributes.reseller_price, dataProduct.attributes.original_currency)
        }

        // check sale price
        var set_salePrice = ''
        if (Number(dataProduct.attributes.sale_price) > 0) {
          set_salePrice = this.formatMoney(dataProduct.attributes.sale_price, dataProduct.attributes.original_currency)
        }

        let newItem: any = {
          value: false,
          productName: dataProduct.attributes.title,
          description: dataProduct.attributes.description == '' ? 'No description provided' : dataProduct.attributes.description,
          productId: dataProduct.attributes.id,
          currency: upperCase(dataProduct.attributes.currency),
          salePrice: Number(dataProduct.attributes.sale_price),
          salePriceWithCurrency: set_salePrice,
          resellerPrice: Number(dataProduct.attributes.reseller_price),
          resellerPriceWithCurrency: set_resellerPrice,
          price: this.formatMoney(dataProduct.attributes.regular_price, dataProduct.attributes.original_currency),
          quantity: this.formatNumber(dataProduct.attributes.quantity),
          quantityUnit: dataProduct.attributes.quantity_unit,
          weight: this.formatNumber(dataProduct.attributes.weight),
          weightUnit: dataProduct.attributes.weight_unit,
          not_show_in_catalog: [],
          status: false,
          image: null,
          variants: [] as string[],
          actionFab: false,
          original_categories_product: 'No Original Categories',
          tags: [],
          weight_unit: dataProduct.attributes.weight_unit,
          total_my_products: dataProduct.attributes.total_my_products,
          id: dataProduct.attributes.id,
          supplier: dataProduct.relationships.supplier ? included_supplier[dataProduct.relationships.supplier.id].attributes.name : 'No supplier',
          warehouses: [],
          notshowincatalog: dataProduct.attributes.not_show_in_catalog,
          added_by: dataProduct.attributes.added_by,
          original_product: dataProduct.attributes.original_url,
          created_at: this.toFullDate(dataProduct.attributes.created_at.slice(0, 10)),
          updated_at: this.toFullDate(dataProduct.attributes.updated_at.slice(0, 10)),
          priority: dataProduct.attributes.priority,
          owner: []
        }

        // set category
        const responseCategories = dataProduct.relationships.categories

        if (!isEmpty(dataProduct.relationships.categories)) {
          forEach(responseCategories, category => {
            if (included_categories[category.id].attributes.level === 1) {
              this.topCategoryForList = included_categories[category.id].attributes.name
            } else if (included_categories[category.id].attributes.level === 2) {
              this.categoryLevel2ForList = included_categories[category.id].attributes.name
            } else if (included_categories[category.id].attributes.level === 3) {
              this.categoryLevel3ForList = included_categories[category.id].attributes.name
            }
          })
          var set_the_category = this.topCategoryForList + ' > ' + this.categoryLevel2ForList + ' > ' + this.categoryLevel3ForList
          newItem.ali_categories_product = set_the_category
        } else newItem.ali_categories_product = 'No ALI Categories'

        //set images
        if (!isEmpty(dataProduct.relationships.images)) {
          for (const image of dataProduct.relationships.images) {
            forEach(included_image, images => {
              if (image.id == images.id && images.attributes.is_default == true) newItem.image = images.attributes.source
            })
          }
          if (newItem.image == null) newItem.image = 'No Set Image'

        } else newItem.image = 'No Image Available'

        //set tags
        if (!isEmpty(dataProduct.relationships.tags)) {
          for (const tags of dataProduct.relationships.tags) {
            forEach(this.responseProductList.included.tags, tags_data => {
              if (tags.id == tags_data.id) newItem.tags.push(tags_data.attributes.name)
            })
          }
        } else newItem.tags = 'No Tags'

        //set warehouses
        if (!isEmpty(dataProduct.relationships.warehouses)) {
          for (const warehouse of dataProduct.relationships.warehouses) {
            forEach(this.responseProductList.included.warehouses, warehouse_data => {
              if (warehouse.id == warehouse_data.id) newItem.warehouses.push(warehouse_data.attributes.name)
            })
          }
        } else newItem.warehouses = 'No Warehouse'

        //set original category
        if (dataProduct.attributes.source_id != null && dataProduct.attributes.last_source_category_id != null) {
          const originalCategory_opts: any = {
            params: {
              'filter[source_id][is]': dataProduct.attributes.source_id,
              'filter[source_category_id][is]': dataProduct.attributes.last_source_category_id
            }
          }
          ProductService.getOriginalCategory(originalCategory_opts).then(response => {
            if (!isEmpty(response.data)) {
              newItem.original_categories_product = ''
              const original_categories = response.data[0].attributes.categories
              let length_originalCategory = original_categories.length - 1

              forEach(original_categories, (category, index) => {
                if (index != length_originalCategory.toString()) {
                  newItem.original_categories_product += category + ' > '
                } else newItem.original_categories_product += category
              })
            }
          })
        }

        //set country & city
        if (!isEmpty(dataProduct.relationships.country)){
          newItem.country = included_country[dataProduct.relationships.country.id].attributes.name
        } else newItem.country = 'No country'

        // set creator
        if (!isEmpty(dataProduct.relationships.creator)){
          newItem.creator = included_creator[dataProduct.attributes.created_by].attributes.name
        }

        if(!isEmpty(dataProduct.relationships.cities)){
          newItem.city = included_cities[dataProduct.relationships.cities.id].attributes.name
        } else newItem.city = 'No city'

        // owner list
        if (!isEmpty(dataProduct.relationships.owner)){
          for (const owner_relationship of dataProduct.relationships.owner){
            newItem.owner.push(included_owner[owner_relationship.id].attributes)
          }
        }

        if (!isEmpty(dataProduct.relationships.supplier)){
          const id = dataProduct.relationships.supplier.id
          const opts: any = {
            params: {
              include: 'city'
            }
          }
          SupplierService.getOneSupplier(id, opts)
            .then(response => {
              if(response.data.attributes.city_id != null) newItem.city_supplier = response.included.city[response.data.attributes.city_id].attributes.name+', '+ response.included.country[response.data.attributes.country_id].attributes.name
            })
        } else newItem.city_supplier = 'No City'

        this.tableItems.push(newItem)
        this.selectedProductID = []
        this.checkboxAllSelected = false
      })
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.tableLoading = false
    }
  }

  async syncProduct() {
    try {
      const valid = await this.$validator.validateAll()
      if (valid) {
        this.syncProductDialog = false
        this.showLoading({ text: 'Syncing Product...' })
        const response = await ProductService.syncProduct(this.syncProductId)
        this.getProductLists()
        this.closeLoading()
        this.showSnackbar({ text: startCase(response.data.message), color: 'teal', timeout: 2500 })
      } else {
        this.showSnackbar({ text: 'Please check all filed(s) requirements', color: 'red', timeout: 3500 })
      }
    } catch (error) {
      this.catchHandler(error)
    }
  }

  onProductDeleted(index) {
    this.alert_bulkDelete = true
    this.idDelete = index
  }

  async onProductDeletedSave() {
    try {
      this.showLoading({ text: 'Deleting...' })
      let response

      if(this.idDelete != null){
        response = await ProductService.deleteProduct(this.idDelete)

      } else if (!isEmpty(this.selectedProductID)){
        const opts = {
          data : { ids : this.selectedProductID }
        }
        response = await ProductService.deleteProducts(opts)
      }

      if (response.data.success) {
        await this.getProductLists()

        this.showSnackbar({
          text: 'Deleted Successfully!',
          color: 'teal',
          timeout: 1500
        })
      } else {
        this.showSnackbar({
          text: 'Delete Failed!',
          color: 'red',
          timeout: 2000
        })
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.idDelete = null
      this.closeLoading()
      this.alert_bulkDelete = false
    }
  }

  onCollectorModalOpen(id) {
    this.selectedIdProducts = id
    this.getCollectors()
    this.showCollectorModal = true
  }

  onCollectorModalClose() {
    this.showCollectorModal = false
  }

  async getCollectors() {
    try {
      this.tableLoadingCollector = true
      const opts = {
        params: {
          'page[num]': this.tablePaginationCollector.page,
          'page[limit]': this.tablePaginationCollector.rowsPerPage,
          sort: this.tablePaginationCollector.descending ? '-id' : 'id'
        }
      }
      const response = await ProductService.getProductCollectors(this.selectedIdProducts, opts)
      this.tableItemsCollector = []
      forEach(response.data.data, dataCollector => {
        this.tableItemsCollector.push(dataCollector.attributes)
      })
      this.tableTotalItemsCollector = response.data.meta.pagination.total
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.tableLoadingCollector = false
    }
  }

  // Clone Product
  cloneProductConfirmationModal: boolean = false
  idCloneProduct: any = null

  // Clone Product
  onCloneProduct(id) {
    this.cloneProductConfirmationModal = true
    this.idCloneProduct = id
  }

  async cloneProduct () {
    try {
      this.showLoading({ text: 'Cloning product...' })

      const payload = {
        id: this.idCloneProduct
      }
      const response = await ProductService.cloneProduct(payload)

      if (response.data.success) {
        await this.getProductLists()

        this.showSnackbar({
          text: 'Clone Product Successfully!',
          color: 'teal',
          timeout: 1500
        })
      } else {
        this.showSnackbar({
          text: 'Clone Product Failed!',
          color: 'red',
          timeout: 2000
        })
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.idCloneProduct = null
      this.closeLoading()
      this.cloneProductConfirmationModal = false
      await this.getProductLists()
    }
  }
}
